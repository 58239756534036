@import '../../../../Common/theme/variables';

.sign-up-password-strength-text {
  font-size: $mediumFontSize;
  color: $textPrimary;
}

.sign-up-terms-of-service {
  font-size: $smallFontSize;
  color: $textSecondary;
  a {
    font-size: $smallFontSize;
  }
}

@media (min-width: $smallBreakPoint) {
  .sign-up-password-strength-progress {
    margin-left: 8px;
  }
}
