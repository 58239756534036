@import '../../../../../../Common/theme/variables';

.room-card {
  cursor: pointer;
  background-size: cover;
  transition: transform 0.3s, box-shadow 0.2s ease-in-out !important;
  box-shadow: none !important;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 40px 0 $textSecondary !important;
  }

  .without-text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .participants-in-room {
    position: absolute;
    right: 7px;
    bottom: -6px;

    .MuiIconButton-root:hover {
      background-color: transparent;
    }
  }

  .room-card-content {
    width: 100%;
    padding-left: $mobileBlockSize * 2;

    .MuiGrid-container {
      position: relative;
    }

    .room-card-content-title {
      font-size: $x-largeFontSize;
      color: $textPrimary;
      padding-top: $mobileBlockSize * 7;
      padding-bottom: 0;
      max-width: $desktopMinWidth - ($mobileBlockSize * 10);
    }
    .room-card-content-creator {
      color: $textSecondary;
      padding-top: 0;
    }

    &-recording-message {
      text-shadow: 0px 0px 5px #000000;
    }
  }
}

@media screen and (min-width: $smallBreakPoint) {
  .room-card {
    .room-card-content {
      .room-card-content-title {
        max-width: $desktopMinWidth - ($desktopBlockSize * 10);
      }
    }
  }
}

@media screen and (max-width: 771px) and (min-width: $smallBreakPoint) {
  .participants-in-room {
    button {
      position: absolute;
      right: 0;
      bottom: 0;
    }
    [data-testid="participant-count"] {
      position: absolute;
      right:0;
      bottom:13px;
    }
  }
}
