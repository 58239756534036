$loader-style: double;
$loader-width: 0.5em;
$loader-color: #ecf0f1;
$loader-speed: 0.55s;

.progress-bar {
  width: 1em;
  height: 1em;
  position: relative;

  &:before,
  &:after {
    content: '';
    top: 0;
    display: block;
    width: 1em;
    height: 1em;
    position: absolute;
    border-width: $loader-width;
    border-style: $loader-style;
    border-color: transparent;
    box-sizing: border-box;
    border-radius: 1em;
    animation: spin $loader-speed infinite;
  }
  &:after {
    left: 0;
    border-left-color: $loader-color;
  }
  &:before {
    right: 0;
    border-right-color: $loader-color;
    animation-delay: calc($loader-speed / -4);
  }
}

.progress-bar-default-size {
  font-size: 150px;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
}
