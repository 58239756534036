@import '../../Common/theme/variables';

.sso-form {
  width: 100%;
  padding: 0 $mobileBlockSize * 2;
  .back-link {
    text-align: center;
  }
}

.back-link {
  text-align: center;
}

@media screen and (min-width: $smallBreakPoint) {
  .sso-form {
    padding: 0;
    .back-link {
      width: 100%;
    }
  }
}
