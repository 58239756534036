@import '../../../../../../Common/theme/variables';

.lobby {
  height: 100%;
  width: 100%;

  a {
    font-size: 1rem;
    color: $rootSecondary;
  }

  .lobby-grid {
    height: 100%;
    width: 100%;

    .lobby-camera-preview-column {
      height: auto;
      width: 100%;
    }

    .lobby-room-data-column {
      height: auto;
      background: linear-gradient(
        192.09deg,
        rgba(114, 150, 164, 0.72) -46.24%,
        rgba(4, 3, 4, 0.6) 91.17%
      );

      .lobby-room-button {
        width: 4.35 * $desktopBlockSize;
        height: 4.35 * $desktopBlockSize;
      }
    }
  }
}
