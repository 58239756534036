@import '../../../../Common/theme/variables';

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.chat-message-item {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-wrap: wrap;
  min-height: $mobileBlockSize * 7;
}

.chat-message-item-content {
  order: 1;
  flex: 1;
  overflow: hidden;
  word-wrap: break-word;
}

.chat-message-item-description {
  order: 4;
  width: 100%;
  white-space: pre-wrap;
}

.chat-message-item-edited {
  opacity: 0.7;
}

@media screen and (min-width: $largeBreakPoint) {
  .chat-message-item {
    flex-wrap: nowrap;
    min-height: $desktopBlockSize * 6.5;

    .chat-message-item-content {
      padding: 0 $desktopBlockSize;
    }

    .chat-message-item-description {
      order: 2;
      flex: 1;
      margin-top: 0;
    }
  }
}
