@import '../../../../../../../../Common/theme/variables';

.blurred-background {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url('./images/bg.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &-content {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(40px);
    position: relative;
  }
}
