.sso-login {
  width: 100%;
  padding: 0;
  margin: 0;
  legend {
    opacity: 0;
    padding-bottom: 10px;
  }
  .MuiOutlinedInput-root {
    border-radius: 0;
  }
  .MuiGrid-spacing-xs-4 > .MuiGrid-item:nth-child(2) {
    margin-bottom: 15px;
  }
  .MuiOutlinedInput-notchedOutline legend {
    padding-left: 2px;
  }
  .MuiOutlinedInput-notchedOutline legend span {
    font-size: 14px;
  }
  & .MuiInputLabel-formControl,
  & .MuiOutlinedInput-notchedOutline legend {
    position: relative;
    top: 17px;
    color: #fff;
    margin: 0 auto;
  }
  .MuiOutlinedInput-notchedOutline {
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-top: 1px solid #fff;
    opacity: 0.25;
    &:hover {
      opacity: 0.25;
    }
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
}
