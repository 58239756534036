@import '../../Common/theme/variables';

.container {
  padding: 45px;
  margin: 0 $mobileBlockSize;
  margin-bottom: $mobileBlockSize;
  border-radius: 10px;
}

@media (min-width: $smallBreakPoint) {
  .container {
    padding: 45px;
    max-width: 38 * $desktopBlockSize;
    border-radius: 10px !important;
  }
}
