@import '../../../../../Common/theme/variables';

.home-view {
  color: $textPrimary;
  padding-top: $mobileBlockSize * 2;

  .new-room-btn {
    border-radius: 0;
    color: $textPrimary;
    background-color: $rootSecondary !important;
    :hover {
      color: $textPrimary;
      background-color: $rootSecondary !important;
    }
  }

  .home-view-credits {
    font-size: $mediumFontSize;
  }
}

@media (min-width: $smallBreakPoint) {
  .home-view {
    padding-top: $desktopBlockSize * 3;
  }
}
