@import '../../Common/theme/variables';

.card-subtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 $mobileBlockSize;
  font-size: $largeFontSize;
  color: $textPrimary;
}

@media (min-width: $smallBreakPoint) {
  .card-subtitle {
    padding: 0 $desktopBlockSize;
  }
}
