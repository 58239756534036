.default-dialog-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}

.default-dialog-header-title {
  font-size: 16px;
  font-weight: normal;
}

.closeButton {
  float: right;
  margin: -2px -4px -2px 0px;
}
