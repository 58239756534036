$smallBreakPoint: 600px;
$mediumBreakPoint: 960px;
$largeBreakPoint: 1280px;
$xlargeBreakPoint: 1920px;

$smallFontSize: 12px;
$mediumFontSize: 14px;
$baseFontSize: 16px;
$largeFontSize: 20px;
$x-largeFontSize: 24px;
$xx-largeFontSize: 28px;

$bigIconSize: 48px;

$mobileBlockSize: 12px;
$desktopBlockSize: 16px;
$borderRadius: 0px;

$desktopMinWidth: 500px;

$transition-time: 0.3s;

$backgroundColor: #121212;

$overlay-bg-mobile: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.5) 50%);
$overlay-bg-desktop: rgba(18, 18, 18, 0.4);

$seekbar-bg: rgba(18, 18, 18, 0.5);

// palette
$rootPrimary: #121212;
$rootSecondary: #fc2c03;

$textPrimary: #ffffff;
$textSecondary: #a8a8a8;

$backgroundPrimary: #252525;
$backgroundSecondary: #2e2e2e;

$inputUnderline: rgba(255, 255, 255, 0.16);
$inputUnderlineHover: rgba(255, 255, 255, 0.2);
$inputBackground: rgba(255, 255, 255, 0.12);

$modalBackground: rgba(255, 255, 255, 0.1);
$modalBackdropFilter: blur(100px);

$infoConfirm: #43a047;
$infoNotice: #eeaa22;
$infoWarn: #ff7700;
$infoDeny: #dd2c00;

$divider: #3f3f3f;

$scrollbar: #3f3f3f;
$scrollbarHover: #545454;
