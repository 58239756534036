@import '../../Common/theme/variables';

.card-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: $xx-largeFontSize;
  color: $textPrimary;

  .card-title-icon {
    .MuiSvgIcon-root {
      font-size: $bigIconSize;
    }
  }
}

.text-left {
  text-align: left;
}
