@import '../../../Common/theme/variables';

.desktop-app-launch-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  font-weight: normal;
  overflow-y: auto;
  background: url('../../../Static/images/launch_page_background.svg') no-repeat;
  background-size: cover;
  padding-top: 190px;

  .desktop-app-launch-description {
    color: white;
    font-size: 34px;
    text-align: center;
    max-width: 510px;
    margin: 0 25px 25px;
  }

  .desktop-app-launch-content {
    display: flex;
    flex: 0.75;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    max-height: 50%;
  }

  .desktop-app-launch-description-alternative {
    color: transparentize(white, 0.2);
    font-size: 24px;
    text-align: center;
    margin: 0 20px 0;
  }

  .desktop-app-launch-alternatives {
    color: transparentize(white, 0.2);
    font-size: 24px;
    margin: 0 25px 15px;
    text-align: center;
  }

  .desktop-app-launch-button-link {
    display: inline-block;
    position: relative;
    color: white;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 4px;
      width: 98%;
      left: calc(2% / 2);
      height: 2px;
      border-radius: 2px;
      background: white;
      opacity: 0;
      transform: translateY(-2px);
      -webkit-transition: opacity ease-in-out 0.1s, transform ease-in-out 0.1s;
      -moz-transition: opacity ease-in-out 0.1s, transform ease-in-out 0.1s;
      transition: opacity ease-in-out 0.1s, transform ease-in-out 0.1s;
    }

    &:hover {
      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .desktop-app-launch-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border: 1px solid white;
    padding: 15px 30px;
    margin: 20px 0 20px;
    width: 80%;
    max-width: 486px;
    min-height: 73px;
    cursor: pointer;
    user-select: none;
    background-size: calc(200% + 4px) 100%;
    background-image: linear-gradient(to left, white 50%, transparent 50%);
    -webkit-transition: background-position ease-in-out 0.4s;
    -moz-transition: background-position ease-in-out 0.4s;
    transition: background-position ease-in-out 0.4s;

    .desktop-app-launch-button-content {
      font-size: 24px;
      mix-blend-mode: difference;
    }

    .desktop-app-launch-button-arrow {
      width: 25px;
      height: 25px;
      margin-left: 12px;
      mix-blend-mode: difference;
    }

    &:hover {
      background-position: calc(-100% - 2px) 0;
    }
  }
}
