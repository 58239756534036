@import '../../../../../Common/theme/variables';

.device-selection-preview-section {
  width: 346px;
  height: 240px;
  display: flex;

  .device-selection-preview {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .device-selection-camera-not-selected {
    height: 100%;
    width: 100%;
    display: flex;
    background-color: $inputBackground;
  }
}
