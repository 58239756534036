@import '../../../../../../../Common/theme/variables';

.camera-preview {
  width: 100%;
  height: 100%;
  min-height: $mobileBlockSize * 48;
  overflow: hidden;
  position: relative;

  .camera-preview-stream {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media (min-width: $smallBreakPoint) {
  .camera-preview {
    min-height: $desktopBlockSize * 28;
  }
}
