@import '../../../../../Common/theme/variables';

.recordings-tab {
  .recordings-tab-actions {
    color: $textSecondary;

    .recordings-tab-action {
      cursor: pointer;
    }
  }

  .recordings-tab-content {
    overflow-y: auto;

    .recordings-tab-content-no-items {
      text-align: center;
    }
  }
}
