@import '../../../../../Common/theme/variables';

.settings-view {
  padding-top: $mobileBlockSize * 3;

  .settings-view-content {
    padding: 0;

    &:last-child {
      padding-bottom: 0;
    }

    .settings-view-content-padding {
      padding: $mobileBlockSize * 1.5 $mobileBlockSize;
    }

    .settings-view-content-title {
      color: $textPrimary;
      font-weight: 500;
    }
  }
}

@media (min-width: $smallBreakPoint) {
  .settings-view {
    padding-top: $desktopBlockSize * 3;

    .settings-view-content {
      .settings-view-content-padding {
        padding: $desktopBlockSize * 1.5 $desktopBlockSize;
      }
    }
  }
}
