@import '../../../App/Common/theme/variables';

.status-component {
  &-title {
    font-weight: normal;
    font-size: $largeFontSize;
    line-height: $x-largeFontSize;
    margin: 0;
    color: $textPrimary;
  }

  & img {
    width: 89px;
  }
}
