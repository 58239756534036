@import '../../Common/theme/variables';

$z-index-thumbnail-overlay: 99;
$z-index-icons: 100;
// $z-index-thumbnail-connectivity-stats: 101; // used in other ThumbnailConnectivity.scss
$z-index-participant-name: 102;

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  z-index: $z-index-thumbnail-overlay;
}

.thumbnail {
  background: $backgroundPrimary;
  overflow: hidden;
  position: relative;
  height: $mobileBlockSize * 10;
  width: $mobileBlockSize * 13.25;
  cursor: pointer;

  &-overlay-connection-lost {
    @extend .overlay;
    font-size: $smallFontSize;
    font-weight: bold;
    color: white;

    &-icon.MuiSvgIcon-root {
      color: $rootSecondary;
      font-size: $xx-largeFontSize * 2;
    }
  }

  &-participant-speaking {
    border: 2px solid $rootSecondary;
  }

  &-visible-icon {
    position: absolute;
    bottom: $mobileBlockSize * 0.75;
    right: $mobileBlockSize * 0.75;
  }

  &-content,
  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-participant-name {
    transition: opacity 0.2s ease-out;
    margin: 0;
    position: absolute;
    left: $mobileBlockSize * 0.5;
    top: $mobileBlockSize * 0.5;
    font-style: normal;
    font-weight: 500;
    font-size: $mediumFontSize;
    z-index: $z-index-participant-name;
    opacity: 0;
    text-shadow: 0px 1px 4px #000;
    color: $textPrimary;

    &-visible {
      opacity: 1;
    }
  }

  &-overlay {
    @extend .overlay;
    transition: opacity 0.2s ease-out;
    opacity: 0;
    z-index: $z-index-thumbnail-overlay;
    display: flex;
    justify-content: flex-end;
    padding: $mobileBlockSize * 0.5;

    &-visible {
      opacity: 1;
    }

    &-no-bg {
      background: unset;
    }

    &-action-buttons {
      width: $mobileBlockSize * 1.75;

      .toggle-button,
      .thumbnail-toggle-button {
        width: $mobileBlockSize * 1.75;
        height: $mobileBlockSize * 1.75;

        &.MuiButtonBase-root.MuiIconButton-root {
          margin-bottom: $mobileBlockSize * 0.5;
        }

        &.MuiSvgIcon-root {
          width: $mobileBlockSize * 1.25;
          font-size: 1rem;
        }
      }
    }
  }

  &-canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 60px 26px -24px rgba(0, 0, 0, 0.2);

    canvas {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &-network-connectivity-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: $z-index-icons;
  }
}

.thumbnail-participant-name {
  max-width: 80%;
  word-break: break-all;
  background: rgba(0, 0, 0, 0.5);
  padding: 0.2rem 0.4rem;
  border-radius: 16px;
  font-size: 0.75rem;
}

.thumbnail-mute {
  position: absolute;
  bottom: 7px;
  background: #fc2c03;
  padding: 5px 8px 0px 8px;
  border-radius: 100px;
  left: 32px;
  z-index: $z-index-icons;
}

.thunbnail-mute svg {
  width: 15px;
  height: 15px;
}

.thumbnail-lock {
  position: absolute;
  top: 40%;
  left: 40%;
}

@media (min-width: $smallBreakPoint) {
  .thumbnail {
    height: $desktopBlockSize * 10;
    width: $desktopBlockSize * 13.25;

    &-participant-name {
      left: $desktopBlockSize * 0.5;
      top: $desktopBlockSize * 0.5;
    }

    &-overlay {
      padding: $desktopBlockSize * 0.5;

      &-action-buttons {
        width: $desktopBlockSize * 1.75;

        .toggle-button,
        .thumbnail-toggle-button {
          width: $desktopBlockSize * 1.75;
          height: $desktopBlockSize * 1.75;

          &.MuiButtonBase-root.MuiIconButton-root {
            margin-bottom: $desktopBlockSize * 0.5;
          }

          .MuiSvgIcon-root {
            width: $desktopBlockSize * 1.25;
            font-size: 1rem;
          }
        }
      }
    }
  }
}
