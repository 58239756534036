%common-canvas-style {
  position: absolute;
  background: transparent;
  height: 100%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1 1 0;
  align-items: center;
  justify-content: center;

  .lc-drawing {
    height: 100%;
    width: 100%;

    canvas {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }

    canvas:first-child {
      position: absolute;
    }
  }
}

.video-room-canvas {
  @extend %common-canvas-style;
  z-index: 3;

  &.participant {
    z-index: 2;
  }
}

.participant-canvas {
  @extend %common-canvas-style;
  z-index: 1;
  object-fit: contain;
}

.buffer-canvas {
  @extend %common-canvas-style;
  z-index: 1;
}
