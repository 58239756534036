.access-container {
  svg.MuiSvgIcon-root {
    display: none;
  }
  button {
    padding: 6px !important;
    &:hover {
      background-color: transparent;
    }

    .MuiIconButton-root {
      padding: 0;
      height: 28px;
      width: 28px;
      margin: auto 0 auto 9px !important;

    }
  }

  .alert-info {
    display: block;
    width: 268px;
    padding: 9px 11px;
    border: 0.5px solid rgba(255, 255, 255, 0.30);
    border-radius: 6px;
    background: #0f0f0f;
    margin-bottom: 12px;
  }

  &-header {
    color: rgba(255, 255, 255, 0.60);
    font-size: 8px;
    text-transform: uppercase;
    line-height: 13px;
  }
  &-name {
    color: #fff;
    font-size: 16px;
    line-height: 24px;
  }
  &-info {
    color: rgba(255, 255, 255, 0.60);
    font-size: 10px;
    line-height: 16px;
    & .red {
      color: #dc2626;
    }
  }
}