@import '../../../../../../../Common/theme/variables.scss';

.seek-bar {
  &.MuiSlider-root {
    padding-top: $mobileBlockSize * 2;
  }

  .MuiSlider {
    &-rail {
      height: $mobileBlockSize * 0.75;
      background: $rootSecondary;
      opacity: 0.25;
    }

    &-track {
      height: $mobileBlockSize * 0.75;
    }

    &-thumb {
      width: $mobileBlockSize * 1.5;
      height: $mobileBlockSize * 1.5;
      margin-top: -$mobileBlockSize * 0.35;
    }

    &-mark {
      background-color: white;
      margin-left: -1px;
      width: 2px;
      height: 9px;
    }
  }
}
