.login-navigation {
  a {
    font-weight: bold;
    &:hover {
      text-decoration: underline;
    }
  }
  svg {
    position: relative;
    font-size: 16px;
    top: 3px;
  }
}

.social-icon.company-sso {
  svg {
    filter: invert(1);
  }
}

.button-container {
  padding-left: 14px;
}
