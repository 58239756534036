.lobby-room-button svg {
  height: 32px;
  width: 32px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.lobby-room-data-column .lobby-room-button {
  padding-top: 17px;
}
