@import '../../../../../Common/theme/variables';

.room-issue-information {
  margin-top: $mobileBlockSize * 4;
  margin-bottom: $mobileBlockSize * 3;
}

@media (min-width: $smallBreakPoint) {
  .room-issue-information {
    margin-top: $desktopBlockSize * 4;
    margin-bottom: $desktopBlockSize * 3;
  }
}
