.room-control-button {
  margin: 0 20px !important;
  width: 55px;
  height: 50px;
}

.room-control-button svg {
  width: auto;
  height: 18px;
  margin-bottom: 5px;
}

.room-control-button .MuiTypography-button {
  margin-top: 0px !important;
}
