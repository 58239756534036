@import '../../Common/theme/variables';

.header {
  .header-logo {
    width: $mobileBlockSize * 16;
  }

  .header-avatar {
    width: $mobileBlockSize * 3;
    height: $mobileBlockSize * 3;
    cursor: pointer;
  }
}

@media (min-width: $smallBreakPoint) {
  .header {
    .header-avatar {
      width: $desktopBlockSize * 3;
      height: $desktopBlockSize * 3;
      color: $textPrimary;
    }
  }
}
