@import 'Common/theme/variables.scss';

html {
  box-sizing: border-box;
  letter-spacing: 0.2px;
  font-size: $baseFontSize;
  color: $textPrimary;
}

strong {
  font-weight: 500;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  line-height: inherit;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#app {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

a {
  color: $textPrimary;
  cursor: pointer;
  text-decoration: none;
  font-size: $mediumFontSize;
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

//ustom scrollbar style
::-webkit-scrollbar {
  height: $mobileBlockSize;
  width: calc($mobileBlockSize / 1.5);
}

::-webkit-scrollbar-thumb {
  opacity: 0.2;
  border-radius: $borderRadius;
  background: $scrollbar;
  &:hover {
    background: $scrollbarHover;
  }
}

@media screen and (min-width: $mediumBreakPoint) {
  ::-webkit-scrollbar {
    height: $desktopBlockSize;
    width: calc($desktopBlockSize / 1.5);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 100px #393939 inset !important;
}
