@import '../../Common/theme/variables';

.progress-button-loading-bar {
  font-size: 28px;
}

.progress-button {
  &.continue {
    background: #fff;
    font-weight: bold;
    color: #000;
    &:hover {
      background: #ccc;
      color: #000;
    }
  }
  &.back-button {
    border: 1px solid #fff;
    &:hover {
      border: 1px solid #ccc;
    }
  }
}
