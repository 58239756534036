@import '../../../../../../Common/theme/variables.scss';

.recording-card {
  border-radius: $borderRadius;
  cursor: pointer;
  overflow: hidden;

  .recording-card-icon {
    color: $textSecondary;
    font-size: $x-largeFontSize * 3;
  }

  .recording-card-information {
    background-color: $backgroundSecondary;
    color: $textPrimary;

    .recording-card-information-menu {
      text-align: right;
    }

    .recording-card-information-date {
      color: $textSecondary;
      font-size: $mediumFontSize;
    }
  }
}

.active-recording {
  border: 1px solid $rootSecondary;
}

.inactive-recording {
  border: 1px solid $textSecondary;
}
