@import '../../../../../Common/theme/variables';

.message-header-title {
  &.force-nowrap {
    display: flex;
    flex-wrap: nowrap;
  }
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: calc($mobileBlockSize / 3);

  .message-header-title-name {
    order: 0;
    flex-grow: 2;
  }

  .message-header-title-record-time {
    order: 6;
    font-size: $mobileBlockSize * 0.75;
    color: $rootSecondary;
    text-decoration: underline;
  }

  .message-header-title-date {
    &.with-recording-time {
      order: 5;
    }
    order: 2;
    font-size: $mobileBlockSize * 0.75;
    padding-right: $desktopBlockSize * 0.2;
    flex-shrink: 0;
  }

  .message-header-title-actions {
    order: 3;
    flex-shrink: 0;
    padding-top: 0;
    height: $mobileBlockSize * 3;
  }

  .break {
    order: 4;
    flex-basis: 100%;
    height: 0;
  }
}

@media screen and (min-width: $largeBreakPoint) {
  .message-header-title {
    flex-wrap: nowrap;

    .message-header-title-record-time {
      order: 1;
      font-size: $desktopBlockSize * 0.75;
      padding-right: $desktopBlockSize * 0.2;
      flex-grow: 2;
    }

    .message-header-title-date {
      &.with-recording-time {
        order: 2;
      }
      order: 2;
      font-size: $desktopBlockSize * 0.75;
    }

    .message-header-title-actions {
      order: 3;
      height: $desktopBlockSize * 3;
    }

    .break {
      order: 4;
      flex-basis: auto;
      height: 0;
    }
  }
}
