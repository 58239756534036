@import '../../../../Common/theme/variables.scss';

.recordings-view {
  .recordings-view-tabs {
    background-color: $backgroundPrimary;
  }

  .recordings-view-icon {
    font-size: $x-largeFontSize * 4;
  }
}
