@import '../../../Common/theme/variables';

.square-icon-label-button {
  .MuiButton-label {
    display: flex;
    flex-direction: column;
    font-size: $mediumFontSize;
    text-transform: initial;
    margin-top: 4px;

    .MuiButton-startIcon {
      margin: 0;
    }

    .MuiSvgIcon-root {
      font-size: $x-largeFontSize;
    }
  }
}
