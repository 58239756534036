@import '../../../Common/theme/variables';

.chat-message-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  border-radius: $borderRadius $borderRadius 0 0;
  padding-bottom: 4px;
  padding-top: 4px;
}

.chat-load-more {
  display: flex;
  padding: calc($mobileBlockSize / 2);
  justify-content: center;
  & > button {
    border-radius: $borderRadius * 5;
  }
}

@media screen and (min-width: $mediumBreakPoint) {
  .chat-load-more {
    padding: calc($desktopBlockSize / 2);
  }
}
