@import '../../../Common/theme/variables';

.chat-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: $backgroundSecondary;
  position: relative;
}

.editing-chat-or-note {
  & .chat-form-btns {
    display: initial;
  }
}

.chat-form-btns {
  display: none;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.chat-input {
  .MuiFilledInput-root {
    padding-bottom: 2rem; /* should match size of chat-form-btns */
    background-color: $backgroundPrimary;
    &:hover {
      background-color: #383838;
    }
  }
}

@media screen and (min-width: $smallBreakPoint) {
  .chat-form {
    padding: calc($desktopBlockSize / 2);
  }
}
