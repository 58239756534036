@import '../../../../../../Common/theme/variables';

.playback-control-bar {
  color: $textSecondary;

  .playback-control-bar-button {
    width: 3.15 * $desktopBlockSize;
    height: 3.15 * $desktopBlockSize;
  }  
}

@media (min-width: $mediumBreakPoint) {
  .playback-control-bar {  
    .playback-control-bar-button {
      width: 3.15 * $mobileBlockSize;
      height: 3.15 * $mobileBlockSize;
    }
  }
}

.playback-control-bar-seek {  
  color: $textSecondary;
  
  &.MuiTypography-caption {
    font-size: 9px;
    font-weight: bold;
  }
}