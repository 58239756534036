$landing-breakpoint: 1000px;

.landing-view-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  justify-content: center;
  align-content: stretch;
  height: 100%;
  padding: 100px;
  gap: 150px;
}

.landing-view-square img {
  display: none;
}

@media screen and (min-width: $landing-breakpoint) {
  .landing-view-container {
    grid-template-columns: 3fr 2fr;
    justify-items: stretch;
    padding-left: 0;
  }

  .landing-view-square img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: right;
  }

  .landing-view-content {
    overflow-y: auto;
    padding: 1rem;
  }

  .landing-view-content::-webkit-scrollbar {
    width: 2px;
  }
}
