@import '../../Common/theme/variables';

.app-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: inherit;

  .app-error-logo-container {
    margin-top: $mobileBlockSize * 4;
    margin-bottom: $mobileBlockSize * 3;

    .app-error-logo {
      height: $mobileBlockSize * 4;
      width: $mobileBlockSize * 16;
    }
  }

  .app-error-content {
    padding: $mobileBlockSize * 2 $mobileBlockSize * 3;
    margin: 0 $mobileBlockSize;
    margin-bottom: $mobileBlockSize;
  }
}

@media (min-width: $smallBreakPoint) {
  .app-error {
    .app-error-logo-container {
      margin-top: $desktopBlockSize * 4;
      margin-bottom: $desktopBlockSize * 3;

      .app-error-logo {
        height: $desktopBlockSize * 4;
        width: $desktopBlockSize * 16;
      }
    }
  }
}
