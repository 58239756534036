svg.entry-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  /* pointer-events: none; */
}

svg.entry-container > g {
  position: absolute;
  top: 0;
  left: 0;
}

g.inner {
  opacity: 0;
  transform-origin: 50% 50%;
}

.gradient-1 {
  fill: url(#linear-gradient-1);
}
.gradient-2 {
  fill: url(#linear-gradient-2);
}
.gradient-3 {
  fill: url(#linear-gradient-3);
}
.gradient-4 {
  fill: url(#linear-gradient-4);
}

.lg-3 {
  transform: scale(0.2);
}
g.guide {
  background: none;
  fill: none;
}
g.guide > polygon {
  stroke: white;
  stroke-dasharray: 4;
  stroke-width: 1px;
  stroke-opacity: 0.5;
  fill: rgba(0, 0, 0, 0);
}
