@import '../../Common/theme/variables';

.people-list-container-content {
  height: 100%;
  transition: width $transition-time;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
  padding: 16px;
}

.people-list-category {
  color: #BBB;
  font-weight: bold;
  margin-bottom: 8px;
}
