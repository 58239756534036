.loader-container {
  position: relative;
  margin-top: 100px;
  height: 25px;
  margin: 55px 0 35px;
}
  
.loader-gradient {
  position: absolute;
  height: 10px;
  left: 0;
  top: 0;

  background: linear-gradient(90deg, rgba(255, 107, 0, 0.3) 4.94%, rgba(255, 0, 214, 0.3) 36.48%, rgba(82, 0, 255, 0.3) 61.61%, rgba(0, 102, 255, 0.3) 90.01%);
  border-radius: 53px;

  transition: all 2s linear;
}

.loader-blur {
  position: absolute;
  width: 64px;
  height: 32px;
  top: -12px;

  border-radius: 107px;
  filter: blur(16px);

  transition: all 2s linear;
}
