@import '../../Common/theme/variables';

$z-index-thumbnail-connectivity-stats: 101;

.thumbnail-connectivity-stats {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: white;
  padding: $mobileBlockSize * 0.5;
  font-size: 0.65rem;
  display: grid;
  grid-template-columns: auto auto;
  gap: 0.2rem;
  align-content: flex-start;
  justify-content: space-between;
  justify-items: flex-end;
  z-index: $z-index-thumbnail-connectivity-stats;
  -webkit-backdrop-filter: blur(0.5rem) brightness(60%);
  backdrop-filter: blur(0.5rem) brightness(60%);
}

.tcs-item {
  justify-self: flex-start;
}

.tcs-close {
  /* overriding the mui-icon-button without creating another div */
  justify-content: flex-end !important;
  padding: 0 !important;
  margin-bottom: 0.5rem !important;
  grid-column: 1 / 3;
}

/* This breakpoint needs to match Thumbnail.scss */
@media (min-width: $smallBreakPoint) {
  .thumbnail-connectivity-stats {
    padding: $desktopBlockSize * 0.5;
  }
}
