.muted-dialog {
  z-index: 1000;
  position: absolute;
  width: 394px;
  height: 110px;
  background-color: rgba(225, 225, 225, 0.1);
  padding: 16px 24px 24px 24px;
  backdrop-filter: blur(100px);
  border-radius: 4px;
  bottom: 95px;
  left: 33%;
}
