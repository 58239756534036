@import '../../LandingView/LandingView.scss';

.email-name {
  width: 560px;
  word-wrap: break-word;

  @media screen and (max-width: $landing-breakpoint) {
    width: initial;
    max-width: 560px;
    min-width: 376px;
  }
}
