.status-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: right;
  z-index: 100;
}
.status-bar > div {
  display: inline-block;
  margin: 0 5px 5px 0;
}
.status-bar > div > button,
.status-bar > div > button:hover {
  background: transparent;
}
