@import '../../Common/theme/variables';

.general-padding {
  width: 100%;
  padding: 0 $mobileBlockSize * 2;
}

@media (min-width: $smallBreakPoint) {
  .general-padding {
    padding: 0 $desktopBlockSize * 3;
  }
}

@media (min-width: $largeBreakPoint) {
  .general-padding {
    padding: 0 $desktopBlockSize * 4.25;
  }
}
