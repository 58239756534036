@import '../../../../../../../../Common/theme/variables';

.menu-item-with-submenu {
  &.MuiButtonBase-root {
    padding-top: $mobileBlockSize;
    padding-bottom: $mobileBlockSize;
    color: $textPrimary;
  }

  .MuiTypography-root {
    font-size: $mediumFontSize;
    font-weight: 500;
  }

  &.Mui-selected,
  &-inner .MuiTypography-root {
    color: $textPrimary;
  }

  .MuiListItemIcon-root {
    min-width: unset;
    margin-right: $mobileBlockSize * 1.25;
  }
}

@media screen and (min-width: $smallBreakPoint) {
  .menu-item-with-submenu {
    &.MuiButtonBase-root {
      padding-top: $desktopBlockSize * 0.75;
      padding-bottom: $desktopBlockSize * 0.75;
    }

    .MuiTypography-root {
      font-size: $mediumFontSize;
    }

    .MuiListItemIcon-root {
      margin-right: $desktopBlockSize * 0.75;
    }
  }
}
