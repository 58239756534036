.csm-alert-badge {
    position: relative;
    svg {
        position: relative;
        bottom: 8px;
    }
    h5 {
        font-size: 13px;
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translate(-50%, -50%);
        font-weight: normal;
    }
}