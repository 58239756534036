.captcha-loading {
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;

  .captcha-loading-progress {
    font-size: 38px;
  }
}
