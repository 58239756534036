@import '../../../Common/theme/variables';

.chat-header {
  align-items: center;
  flex: 0 0 auto;
  padding: calc($mobileBlockSize / 2);
}

.chat-header-slots {
  & > button {
    margin-left: $mobileBlockSize;
  }
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}

.chat-header-actions {
  color: $textSecondary;
  padding-right: calc($mobileBlockSize / 2);
}

@media screen and (min-width: $mediumBreakPoint) {
  .chat-header {
    padding: calc($desktopBlockSize / 2);
  }

  .chat-header-slots {
    & > button {
      margin-left: $desktopBlockSize;
    }
  }

  .chat-header-actions {
    & > svg {
      margin-left: calc($desktopBlockSize / 2);
    }
  }
}
