@import '../../Common/theme/variables';

.side-drawer {
  &-pinned-button {
    svg {
      path {
        fill-opacity: 1;
      }
    }

    &.MuiButtonBase-root {
      background-color: rgba(255, 255, 255, 0.87);
      color: $backgroundSecondary;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    &-left.MuiButtonBase-root {
      border-radius: 0 0 0 $borderRadius;
    }

    &-right.MuiButtonBase-root {
      border-radius: 0 0 $borderRadius 0;
    }
  }

  &-typography {
    font-weight: 500;
    color: white;
  }

  &-icon.MuiIconButton-sizeSmall {
    .MuiSvgIcon-root {
      font-size: 21px;
      color: rgba(255, 255, 255, 0.5);
    }
  }

  &-content {
    height: 100%;
    overflow: auto;
  }
}
