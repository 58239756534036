@import '../../../Common/theme/variables';

.room-name {
  &-title {
    margin: 0;
    font-weight: bold;
    color: $textPrimary;
  }
  &-name {
    color: $textPrimary;
  }
}
