@import '../../../../../Common/theme/variables';

.datepicker-input-wrapper {
  max-width:182px;
  .MuiInput-underline:before {
    border-bottom: 0;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: transparent !important;
  }
  .MuiInput-underline {
    &:hover:not(.Mui-disabled):before, &:before {
      border-bottom: transparent;
    }
  }

  .MuiInputBase-adornedEnd {
    .MuiInputBase-input {
      display: inline-block;
      font-size:14px;
    }

    .MuiInputAdornment-positionEnd {
      .MuiButtonBase-root {
        padding: 4px;
      }
    }
  }
}

.datepicker-container {
  position: fixed;
  &-arrow-down {
	position: absolute !important;
	left: -90px;
  }

  &-done {
    position: absolute !important;
    border-radius: 0;
    left: -50px;
  }

  &-arrow-left {
    padding: 5px !important;
    border-radius:0;
	  &:hover {
      border-radius: 0;
    }
  }

  .MuiButtonBase-root {
    margin: 0;
  }

  .day-container {
    .MuiPickersDay-current {
      color: #ffffff;
    }

    &.selected {
      .MuiPickersDay-day {
        color: $rootPrimary;
        background: $rootSecondary;
        border-radius: 0;
      }
    }

    &.round-start {
      .MuiButtonBase-root {
        color: $rootPrimary;
        background: $rootSecondary !important;
        border-radius: 4px 0 0 4px !important;
      }
    }

    &.round-end {
      .MuiButtonBase-root {
        color: $rootPrimary;
        background: $rootSecondary !important;
        border-radius: 0 4px 4px 0 !important;
      }
    }
  }


  .MuiPaper-root {
    position: fixed;
    top: 90px !important;

    .MuiPickersBasePicker-container {
      width: 345px;
    }

    .MuiPickersCalendarHeader-iconButton{
      padding: 5px;

      &:hover {
        border-radius: 0;
      }

      &:first-child {
        position: absolute;
        right: 70px;
      }
    }
  
    .MuiPickersDay {
      &-daySelected {
        border-radius: 0;
        background: transparent;
      }
      &-current {
        border-radius: 0;
        background: #c4c4c4;
        color: #000000;
      }
    }

    .MuiPickersDay-day:hover {
      border-radius: 0;
      font-size: 14px;
    }

    .MuiTypography-caption {
      font-size:12px;
    }

    .MuiPickersCalendarHeader-transitionContainer {
      p {
        text-align: left;
        padding-left: 20px;
        text-transform: uppercase;
        font-size: 14px;
      }
    }

    .MuiPickersCalendar-transitionContainer {
      margin-top: 5px;
    }
  }
}

@media (max-width: $mediumBreakPoint) {
  .datepicker-container, .download-dialog-container {
    display: none;
  }
}