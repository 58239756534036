@import '../../Common/theme/variables';

.control-bar {
  &-root {
    background: $overlay-bg-mobile;
  }

  .control-bar-button {
    width: 3.15 * $desktopBlockSize;
    height: 3.15 * $desktopBlockSize;
  }

  &-button {
    max-height: 50px;
  }

  &-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 15ch;
    &:hover {
      overflow: visible;
      max-width: none;
      text-overflow: initial;
    }
  }
}

@media (min-width: $mediumBreakPoint) {
  .control-bar {
    &-root {
      background: $overlay-bg-desktop;
    }

    .control-bar-button {
      width: 3.15 * $mobileBlockSize;
      height: 3.15 * $mobileBlockSize;
    }
  }
}
