.password-strength-weak {
  .MuiLinearProgress-root > .MuiLinearProgress-barColorSecondary {
    background: #ff7070;
    transition: all 0.25s ease-in-out;
  }
}

.password-strength-moderate {
  .MuiLinearProgress-root > .MuiLinearProgress-barColorSecondary {
    background: #fff06a;
    transition: all 0.25s ease-in-out;
  }
}

.password-strength-strong {
  .MuiLinearProgress-root > .MuiLinearProgress-barColorSecondary {
    background: #52ce6d;
    transition: all 0.25s ease-in-out;
  }
}

.password-strength-strongest {
  animation: strongest 0.75s ease-in-out forwards;
  .MuiLinearProgress-root > .MuiLinearProgress-barColorSecondary {
    background: rgba(41, 145, 64, 1);
    transition: all 0.25s ease-in-out;
  }
}

@keyframes strongest {
  0% {
    box-shadow: 0px 0px 8px rgba(41, 145, 64, 0);
  }
  50% {
    box-shadow: 0px 0px 8px rgba(41, 145, 64, 1);
  }
  100% {
    box-shadow: 0px 0px 8px rgba(41, 145, 64, 0);
  }
}
