@import '../../Common/theme/variables';

.card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 $mobileBlockSize;
  font-size: $mediumFontSize;
  color: $textPrimary;
}

@media (min-width: $smallBreakPoint) {
  .card-text {
    padding: 0 $desktopBlockSize;
  }
}

.text-left {
  text-align: left;
  justify-content: flex-start;
  padding: 0;
}
