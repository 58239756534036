@import '../../../Common/theme/variables.scss';

.invite-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: $mobileBlockSize * 16;
  min-height: $mobileBlockSize * 16;
  overflow: auto;
  border-bottom: 1px solid $inputUnderline;
  background-color: $inputBackground;
  border-radius: $borderRadius $borderRadius 0 0;
}

@media screen and (min-width: $mediumBreakPoint) {
  .invite-list {
    max-height: $desktopBlockSize * 16;
    min-height: $desktopBlockSize * 16;
  }
}
