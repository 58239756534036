@import '../../../../Common/theme/variables';

.play-toggle-button {
    .MuiIconButton-root {
        border-radius: 50%; 
    }
}

.play-toggle-button-active {
    &.MuiButtonBase-root, &.MuiButtonBase-root:hover {
        background-color: $textPrimary;
    }
    
    &.MuiIconButton-root, &.MuiIconButton-root:hover {
        color: $rootPrimary;
    }
}

.play-toggle-button-inactive {
    &.MuiButtonBase-root, &.MuiButtonBase-root:hover {
        background-color: $rootSecondary;
    }
    
    &.MuiIconButton-root, &.MuiIconButton-root:hover {
        color: $rootPrimary;
    }
}