@import '../../Common/theme/variables';

.chats-content {
  height: 100%;
  transition: width $transition-time;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: calc(100% - 72px);
}
